import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider";
import AsyncEntityProductsTable from "../../../../Catalog/components/ProductsTable";

const SiteProducts = ({ entity }) => {
    const { apiClient } = useVolcanoApiClient()

    if (!entity) {
        return null;
    }

    const fetcher = () => 
        apiClient.catalog.product.getProducts({limit: 1000, visible: true, site_id: entity.id, rate_group_id: entity?.rate_group?.id})
        .then((collection) => {
            return collection.getItems().map((product) => {
                product.container = {
                    id: entity.id,
                    name: entity.name,
                }

                return product
            })
    }) 

    return <AsyncEntityProductsTable entity={entity} fetcher={fetcher} hideCustomConditions={true} />
}

export default SiteProducts