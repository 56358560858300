import { Box, TableCell, FormHelperText } from '@mui/material';
import Text from "../../../../components/Display/Text"
import { getRateName } from "./BookingRates.functions"
import { formatPrice } from '../../../../lib/utils';
import { useState } from "react"
import _get from 'lodash/get'
import NumericField from '../../../../components/form/NumericField';

const AmountTextField = ({ id, value, onChange, currency }) => {
    return (
        <Box display="inline-flex">
            <NumericField
                id={id.toString()}
                name={id.toString()}
                value={value}
                type="currency"
                InputProps={{ size: "small", sx: { marginTop: "-2px", paddingY: "0px" } }}
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
                sx={{ paddingTop: 0 }}
                variant="outlined"
                size="small"
                currency={currency}
                min={0}
            />
        </Box>
    )
}

const BookingRatesBasicData = (props) => {
    const {
        rate,
        allowEditAmount,
        hideBasePrice,
        hideNetPrice,
        hideRefundCost,
        i18n,
        unitPrice,
        t,
        currency,
        mainCurrency,
        costExchangeRate,
        exchangeRate,
        onChange
    } = props

    const { pvp, neto, collaborator_pvd: pvd } = rate
    const refundCost = _get(rate, "refund_cost.value", null)
    const [value, setValue] = useState(unitPrice)

    const handleOnChange = (newValue, parsedValue, isValid) => {
        debugger
        setValue(parsedValue)
        if (isValid && parsedValue !== value) {            
            onChange(parsedValue)
        }
    }

    return (
        <>
            <TableCell align="left">
                <Text>{getRateName(rate)}</Text>
                {!hideRefundCost && refundCost !== null && <FormHelperText>
                    {t("bookings.rates_edit_form.cost.message", { refund_cost: formatPrice(i18n, currency, refundCost, mainCurrency, costExchangeRate) })}
                </FormHelperText>}
            </TableCell>
            <TableCell align="right">
                {(allowEditAmount && hideNetPrice) && <AmountTextField
                    id={rate.id}
                    value={value}
                    currency={currency}
                    onChange={handleOnChange}
                />}
                {!(allowEditAmount && hideNetPrice) && <Text>{formatPrice(i18n, currency, pvp, mainCurrency, exchangeRate)}</Text>}
            </TableCell>
            {!hideNetPrice && <TableCell align="right">
                {allowEditAmount && <AmountTextField
                    id={rate.id}
                    value={value}
                    currency={currency}
                    onChange={handleOnChange}
                />}
                {!allowEditAmount && <Text>{formatPrice(i18n, currency, neto, mainCurrency, exchangeRate)}</Text>}
            </TableCell>
            }
            {!hideBasePrice && <TableCell align="right"><Text>{formatPrice(i18n, currency, pvd, mainCurrency, exchangeRate)}</Text></TableCell>}
        </>
    )
}

export default BookingRatesBasicData