import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useTranslation } from "react-i18next"
import NumericField from "../../../../components/form/NumericField"
import { formatPrice } from "../../../../lib/utils"
import { useEffect, useState } from "react"


/**
 * Check if a value is a valid number.
 *  
 * @param {*} value 
 * @returns 
 */
const isValidNumber = (value) => {
    return /^-?\d+(\.\d+)?$/.test(value)
}

/**
 * Check if rates net price and commission rate are valid values
 * @param {*} rates 
 */
const validateRates = (rates) => {
    return rates.every(rate => {
        // check if net price and commission rate are valid numbers 
        return isValidNumber(rate.net_price) && isValidNumber(rate.commission_rate) && 
            rate.net_price >= 0 && rate.commission_rate >= 0 && 
            rate.net_price <= rate.retail_price
    })
}       

const RateRowComponent = ({ rate, onChange }) => {
    const { i18n } = useTranslation("vbms")
    const [value, setValue] = useState(rate)

    useEffect(() => {
        if (!value) {
            return
        }

        // check value has changed
        if (value !== rate && (value.active !== rate.active || value.net_price !== rate.net_price || value.commission_rate !== rate.commission_rate)) {
            onChange(value)
        }
    }, [value, rate, onChange])

    const onChangeHandler = (field, value, processedValue, isValid) => {
        if (rate[field] !== value) {
            if (field === 'active') {
                setValue((prev) => ({ ...prev, [field]: value }))
            } else {
                // update net_price or commission_rate only if the value is different
                if (rate[field] === value) {
                    return
                }

                setValue((prev) => {
                    const newRateValue = { ...prev, [field]: processedValue }
                    if (!isValid) {
                        if (field === 'net_price') {
                            newRateValue.commission_rate = null
                        } else if (field === 'commission_rate') {
                            newRateValue.net_price = null
                        }
                        return newRateValue
                    }
                        
                    if (field === 'net_price') {
                        const newCommissionRate = ((newRateValue.retail_price - newRateValue.net_price) / newRateValue.retail_price) * 100
                        // only update commission_rate if it is different
                        if (!isValidNumber(newRateValue.commission_rate)) {
                            newRateValue.commission_rate = 0
                        }
                        if (newRateValue.commission_rate.toFixed(4) !== newCommissionRate.toFixed(4)) {
                            newRateValue.commission_rate = newCommissionRate
                        }
                    } else if (field === 'commission_rate') {
                        const newNetPrice = newRateValue.retail_price * (1 - newRateValue.commission_rate / 100)
                        // only update net_price if it is different
                        if (!isValidNumber(newRateValue.net_price)) {
                            newRateValue.net_price = 0
                        }
                        if (newRateValue.net_price.toFixed(4) !== newNetPrice.toFixed(4)) {
                            newRateValue.net_price = newNetPrice
                        }
                    }

                    return newRateValue
                })
            }
        }
    }

    return (
        <TableRow key={rate.id}>
            <TableCell>{rate.name}</TableCell>
            <TableCell align="right">{formatPrice(i18n, "EUR", rate.retail_price, "EUR", 1)}</TableCell>
            <TableCell align="right">
                <NumericField
                    id="net_price"
                    value={rate.net_price}
                    onChange={(value, processedValue, isValid) => onChangeHandler("net_price", value, processedValue, isValid)}
                    currency="EUR"
                    variant="outlined"
                    size="small"
                    type="currency"
                    min={0}
                    max={rate.retail_price}
                />
            </TableCell>
            <TableCell align="right">
                <NumericField
                    value={rate.commission_rate}
                    onChange={(value, processedValue, isValid) => onChangeHandler("commission_rate", value, processedValue, isValid)}
                    variant="outlined"
                    size="small"
                    type="percentage"
                    min={0}
                    max={100}
                />
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={rate.active}
                    onChange={(e) => onChangeHandler("active", e.target.checked, e.target.checked, true)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </TableCell>
        </TableRow>
    )
}

const RatesContainerEditor = ({ rates, onChange }) => {
    const { t } = useTranslation("vbms")

    const onChangeHandler = (newRate) => {
        const updatedRates = rates.map(rate => (rate.id === newRate.id) ? newRate : rate)
        onChange(updatedRates, validateRates(updatedRates))
    }

    return (
        <TableContainer component={Box}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("catalog.rates_table.rates.name")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.retail_price")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.net_price")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.commission_rate")}</TableCell>
                        <TableCell>{t("catalog.rates_table.rates.active")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rates.map(rate => (<RateRowComponent key={rate.id} rate={rate} onChange={onChangeHandler} />))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RatesContainerEditor