import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Alert, AlertTitle, Box, Button, Grid, Paper } from "@mui/material"
import { format as dateFormat, parseISO } from "date-fns"
import enLocale from "date-fns/locale/en-GB"
import esLocale from "date-fns/locale/es"
import ProcessSteps from "../../../ProcessSteps/ProcessSteps"
import ProductSelector from "../../ProductSelector/ProductSelector"
import DateSelector from "../../DateSelector/DateSelector"
import RatesSelector from "./RatesSelector"
import ClientForm from "./ClientForm"
import _get from "lodash/get"
import _set from "lodash/set"
import Loading from "../../../Display/Loading"
import { useNavigate } from "react-router-dom"
import KeyValue from "../../../Display/KeyValue"
import _has from 'lodash/has';
import BookingWidgetSummary from "./BookingWidgetSummary"
import IntermediarySelector from "./IntermediarySelector"
import SimpleProductSelector from "../../ProductSelector/SimpleProductSelector"
import LanguagesWeekdays from "../../LanguagesWeekdays/LanguagesWeekdays"
import { getCurrencyLocale } from "../../../../lib/utils"

const localeMap = {
    en: enLocale,
    es: esLocale,
}

/** Available selection steps */
const STEP_INTERMEDIARY = 0
const STEP_PRODUCT = 1
const STEP_DATE = 2
const STEP_RATES = 3
const STEP_CLIENT = 4
const STEP_REVIEW = 5
const STEP_PAYMENT = 6

const DEFAULT_STEPS = [
    STEP_PRODUCT,
    STEP_DATE,
    STEP_RATES,
    STEP_CLIENT,
    STEP_REVIEW,
]

const BASE_SELECTION = {
    step: STEP_PRODUCT,
    intermediary: null,
    office: null,
    salesman: null,
    categoryId: null,
    experienceId: null,
    productId: null,
    product: null,
    date: null,
    session: null,
    rates: [],
    cart: null,
    client: null,
    participants: null,
    payment_gateway: null,
}

/**
 * Returns the step title based on the step number
 *  
 * @param {*} step 
 * @returns 
 */
const stepGetTitle = (step) => {
    switch (step) {
        case STEP_INTERMEDIARY:
            return "booking_widget.steps.intermediary.title"
        case STEP_PRODUCT:
            return "booking_widget.steps.product.title"
        case STEP_DATE:
            return "booking_widget.steps.date.title"
        case STEP_RATES:
            return "booking_widget.steps.rates.title"
        case STEP_CLIENT:
            return "booking_widget.steps.client.title"
        case STEP_REVIEW:
            return "booking_widget.steps.review.title"
        case STEP_PAYMENT:
            return "booking_widget.steps.payment.title"
        default:
            return null
    }
}

/**
 * Returns the initial selection object
 *  
 * @param {*} selection 
 * @returns 
 */
const initSelection = (selection) => {
    let result = {
        ...BASE_SELECTION,
        ...selection,
    }

    if (result.productId) {
        result.productId = parseInt(result.productId)
    }

    return result
}

/**
 * Returns the total quantity of tickets selected
 *  
 * @param {*} rates 
 * @returns 
 */
const getRatesQty = (rates) => rates.reduce((acc, rate) => acc + rate.qty, 0)

/**
 * Returns the step to be displayed based on the current selection
 *  
 * @param {*} selection 
 * @returns 
 */
const checkStep = (selection) => {
    if (!selection.product) {
        return STEP_PRODUCT
    }

    if (!selection.date) {
        return STEP_DATE
    }

    if (!selection.cart) {
        return STEP_RATES
    }

    if (selection.cart && !selection.client) {
        return STEP_CLIENT
    }

    if (selection.cart && selection.client) {
        return STEP_REVIEW
    }
}

const getStepLoadingMessage = (step) => {
    switch (step) {
        case STEP_RATES:
            return "booking_widget.steps.rates.loading"
        case STEP_REVIEW:
            return "booking_widget.steps.review.loading"
        default:
            return null
    }
}

/**
 * Returns the available steps configuration based on the current selection
 *  
 * @param {*} selection 
 * @returns 
 */
const getSteps = (product, t, locale, selection, withIntermediary = false) => {
    const availableSteps = withIntermediary ? [STEP_INTERMEDIARY, ...DEFAULT_STEPS] : DEFAULT_STEPS

    return availableSteps.map((step, index) => {
        let result = {
            id: step,
            content: {
                title: t(stepGetTitle(step)),
                text: null,
                info: null,
            },
        }

        switch (step) {
            case STEP_INTERMEDIARY:
                if (selection.intermediary) {
                    result.content.text = selection.intermediary.name
                }

                if (selection.office) {
                    result.content.text += " / " + selection.office.name
                }

                break
            case STEP_PRODUCT:
                if (selection.productId) {
                    result.content.text = product ? product.name : ""
                }
                break
            case STEP_DATE:
                if (selection.date) {
                    result.content.text =
                        dateFormat(selection.date, "P", { locale: localeMap[locale] }) +
                        " " +
                        (selection.session && selection.session.session !== "day_wide"
                            ? selection.session.session.substring(0, selection.session.session.length - 3)
                            : "")
                }
                break
            case STEP_RATES:
                if (selection.session) {
                    result.content.text = t(
                        "booking_widget.steps.rates.selected_tickets",
                        { qty: getRatesQty(selection.rates) }
                    )
                }
                break
            case STEP_CLIENT:
                if (selection.cart && selection.client) {
                    result.content.text = [
                        selection.client.first_name,
                        selection.client.last_name,
                    ].join(" ")
                }
                break
            case STEP_PAYMENT:
                if (selection.cart && selection.payment_gateway) {
                    const priceFormatter = new Intl.NumberFormat(getCurrencyLocale(locale), {
                        style: "currency",
                        currency: "eur",
                    })
                    result.content.text = t(
                        "booking_widget.steps.payment.total_amount",
                        { amount: priceFormatter.format(selection.cart.total_amount) }
                    )
                }
                break
            case STEP_REVIEW:
                result.content.text = ""
                break
            default:
                break
        }

        return result
    })
}

const BookingError = ({ error, onReset }) => {
    const { t } = useTranslation("vbms")

    const processMessage = () => {
        switch (error.type) {
            case "VOLCANO_AVAILABILITY": return t("booking_widget.errors.availability")
            case "VOLCANO_RATES": return t("booking_widget.errors.rates")
            case "VOLCANO_SET_EXISTING_VOUCHER_ID": return t("booking_widget.errors.duplicated_voucher_id")
            default: return t("booking_widget.errors.generic")
        }
    }

    return (
        <Box>
            <Paper elevation={6} sx={{ margin: 3 }}>
                <Alert severity="error">
                    <AlertTitle>{t("booking_widget.errors.title")}</AlertTitle>
                    <Box>{t("booking_widget.errors.subtitle")}</Box>
                    <KeyValue label={t("booking_widget.errors.message_label")} labelVariant="body2">{processMessage()}</KeyValue>
                </Alert>
            </Paper>
            <Box display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onReset}
                >
                    {t("booking_widget.continue")}
                </Button>
            </Box>
        </Box>
    )
}

const BookingProcessStep = (props) => {
    const { step, selection } = props

    const buildStepComponent = () => {
        switch (step) {
            case STEP_INTERMEDIARY:
                return (
                    <IntermediarySelector
                        intermediariesFetcher={props.intermediariesFetcher}
                        featuredIntermediariesFetcher={() => props.featuredIntermediariesFetcher()}
                        selected={selection?.intermediary?.id ?
                            {
                                intermediary: selection.intermediary,
                                office: selection.office,
                                salesman: selection.salesman,
                            } : null
                        }
                        onSelection={props.onSelection}
                    />
                )
            case STEP_PRODUCT:
                if (props.selectorType === "simple") {
                    return (
                        <SimpleProductSelector
                            featuredProductsFetcher={() => props.featuredProductsFetcher(selection?.intermediary?.id)}
                            productsFetcher={(values) => props.productsFetcher(selection?.intermediary?.crm_intermediary_id, values)}
                            selected={selection?.product?.id ? selection.product : null}
                            onSelection={props.onSelection}
                        />
                    )
                }

                return (
                    <ProductSelector
                        categoriesFetcher={props.categoriesFetcher}
                        featuredProductsFetcher={props.featuredProductsFetcher}
                        selected={selection.product ?
                            {
                                category: { id: selection.categoryId },
                                experience: { id: selection.experienceId },
                                product: { id: selection.productId },
                            } :
                            null
                        }
                        onSelection={props.onSelection}
                    />
                )
            case STEP_DATE:
                return (
                    <DateSelector
                        product={selection.product.id}
                        selected={selection.date}
                        minQuantity={_get(selection.product, "qty_restrictions.min", 1)}
                        availabilityFetcher={(productId, date) => props.availabilityFetcher(productId, date, selection?.intermediary?.id || null)}
                        onSelection={props.onSelection}
                    >
                        {selection.product?.languages_weekdays &&
                            <LanguagesWeekdays rules={selection.product.languages_weekdays} />
                        }
                    </DateSelector>
                )
            case STEP_RATES:
                return (
                    <RatesSelector
                        product={selection.productId}
                        date={selection.date}
                        collaborator={selection?.intermediary?.id || null}
                        ratesFetcher={props.ratesFetcher}
                        selected={selection.rates}
                        max={props.max}
                        min={props.min}
                        onChange={props.onSelection}
                        onConfirm={props.onConfirm}
                    />
                )
            case STEP_CLIENT:
                return (
                    <ClientForm
                        cart={selection.cart}
                        defaultClient={selection.client}
                        defaultParticipants={selection.participants}
                        defaultPickup={selection.pickup}
                        pickupData={props.pickupData}
                        onConfirm={props.onConfirm}
                    />
                );
            /*case STEP_PAYMENT:
                return (
                    <PaymentGateway
                        paymentData={selection.cart.payment_data}
                        onPaymentCompleted={props.onPaymentCompleted}
                    />
                );*/
            case STEP_REVIEW:
                return null
            default:
                return null;
        }
    };

    return <Box>{buildStepComponent()}</Box>
}

const BookingWidget = (props) => {
    const {
        defaultSelection,
        intermediariesFetcher,
        featuredIntermediariesFetcher,
        categoriesFetcher,
        productsFetcher,
        featuredProductsFetcher,
        availabilityFetcher,
        ratesFetcher,
        pickupDataFetcher,
        paymentGatewaysFetcher,
        onBookingConfirm,
        onCartConfirm,
    } = props

    const { t, i18n } = useTranslation("vbms")
    const [waiting, setWaiting] = useState(false)
    const [selection, setSelection] = useState(initSelection(defaultSelection || {}))
    const [cartOptions, setCartOptions] = useState({ paymentGateways: [], pickupData: null })
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const bookings = _get(selection, "cart.bookings", [])
        if (bookings.length > 0) {
            navigate("/orders/bookings/" + bookings[0].id)
        }
    }, [selection.cart])

    const updateSelectionForStep = (step) => {
        let newSelection = {}

        switch (step) {
            case STEP_INTERMEDIARY:
                newSelection = {
                    categoryId: null,
                    experienceId: null,
                    productId: null,
                    product: null,
                    date: null,
                    session: null,
                    rates: [],
                    cart: null,
                    client: null,
                    participants: null,
                }
                break
            case STEP_PRODUCT:
                newSelection = {
                    date: null,
                    session: null,
                    rates: [],
                    cart: null,
                    client: null,
                    participants: null,
                }
                if (intermediariesFetcher) {
                    newSelection = {
                        productId: null,
                        product: null,
                        ...newSelection
                    }
                }
                break
            case STEP_DATE:
                newSelection = {
                    date: null,
                    session: null,
                    rates: [],
                    cart: null,
                    client: null,
                    participants: null,
                }
                break
            case STEP_RATES:
                newSelection = {
                    rates: [],
                    cart: null,
                    client: null,
                    participants: null,
                }
                break
            default:
                break

        }

        setSelection((selection) => ({
            ...selection,
            ...newSelection,
            step: step
        }))
    }

    const onResetHandler = () => {
        setSelection(initSelection(defaultSelection || {}))
        setError(null)
    }

    const onIntermediarySelectionHandler = (stepSelection) => {
        const newSelection = {
            ...BASE_SELECTION,
            intermediary: stepSelection.intermediary,
            office: stepSelection.office,
            salesman: stepSelection.salesman,
        }

        setSelection({
            ...newSelection,
            step: checkStep(newSelection),
        })
    }

    // updates selection with the product selected
    const onProductSelectionHandler = (stepSelection) => {
        if (!_has(stepSelection, "product.name")) {
            return
        }

        stepSelection.product.experience = {
            id: stepSelection.experience.id,
            name: stepSelection.experience.name,
        }

        const newSelection = {
            ...BASE_SELECTION,
            intermediary: selection.intermediary,
            office: selection.office,
            salesman: selection.salesman,
            categoryId: stepSelection.category.id,
            experienceId: stepSelection.experience.id,
            productId: stepSelection.product.id,
            product: stepSelection.product,
        }

        // process product container custom configuration if exists
        if (newSelection.product?.container_configuration?.qty_restrictions) {
            newSelection.product.qty_restrictions = {
                min: newSelection.product.container_configuration.qty_restrictions.min,
                max: newSelection.product.container_configuration.qty_restrictions.max,
            }
        }

        setSelection({
            ...newSelection,
            step: checkStep(newSelection),
        })
    }

    // updates selection with the date and session selected
    const onDateSelectionHandler = (stepSelection) =>
        setSelection((selection) => {
            const newSelection = {
                ...selection,
                date: stepSelection.date,
                session: stepSelection.session,
                rates: [],
                cart: null,
                client: null,
                participants: null,
            }

            return {
                ...newSelection,
                step: checkStep(newSelection),
            }
        })

    // updates selection with the rates selected
    const onRatesSelectionHandler = useCallback((rates, isReady) =>
        setSelection((selection) => {
            const newSelection = {
                ...selection,
                rates: rates,
            }

            return {
                ...newSelection,
                step: checkStep(newSelection),
            }
        }), [])

    // add booking to cart
    const onBookingConfirmHandler = useCallback(() => {
        setWaiting(true)
        onBookingConfirm(selection)
            .then((cart) => {
                const lineItemWithPickup = cart.line_items.find((lineItem) =>
                    lineItem.type === "booking_date" && lineItem.product.with_transport === true
                )
                
                // load available payment gateways
                const promises = [                    
                    paymentGatewaysFetcher()
                ]

                if (lineItemWithPickup) {
                    // load pickup data
                    promises.push(pickupDataFetcher(lineItemWithPickup.product.id, parseISO(lineItemWithPickup.booking_date)))
                }

                return Promise.all(promises).then((results) => {
                    const cartOptions = {
                        paymentGateways: cart.total_amount > 0 ? results[0] : [],
                        pickupData: null,
                    }

                    if (lineItemWithPickup) {
                        if (results[1].lodgins.length > 0) {
                            cartOptions.pickupData = {
                                ...results[1],
                                contact_info: _get(
                                    selection,
                                    "product.booking_process_information.pickup_contact_info",
                                    t("booking_widget.steps.client.pickup.validation")
                                ),
                                ignore: false,
                            }
                        } else {
                            cartOptions.pickupData = { ignore: true }
                        }
                    }

                    let selectionData = {
                        cart: cart,
                        client: null,
                        participants: null,
                        payment_gateway: null,
                    }

                    if (cartOptions.paymentGateways.length === 1) {
                        selectionData.payment_gateway = cartOptions.paymentGateways[0]
                    }

                    setCartOptions(cartOptions)

                    setSelection((prev) => {
                        const newSelection = {
                            ...prev,
                            ...selectionData,
                        }

                        return {
                            ...newSelection,
                            step: checkStep(newSelection),
                        }
                    })
                })
            })
            .catch((error) => setError(error))
            .finally(() => setWaiting(false))
    }, [selection])

    const onClientConfirmHandler = useCallback((values) => {
        setSelection((prev) => {
            let clientData = _get(values, "client")
            if (clientData.first_name !== '') {
                clientData.first_name = clientData.first_name.trim()
            }
            if (clientData.last_name !== '') {
                clientData.last_name = clientData.last_name.trim()
            }
            if (clientData.notes !== '') {
                clientData.notes = clientData.notes.trim()
            }
            if (clientData.observations && clientData.observations !== '') {
                clientData.observations = clientData.observations.trim()
            }
            if (clientData.voucher_id !== '') {
                clientData.voucher_id = clientData.voucher_id.trim()
            }

            _set(values, "client", clientData)

            const newSelection = {
                ...prev,
                client: _get(values, "client"),
                participants: _get(values, "participants"),
                pickup: _get(values, "pickup"),
            }

            return {
                ...newSelection,
                step: checkStep(newSelection),
            }
        })
    }, [])

    const onCartConfirmHandler = (paymentGateway) => {
        setWaiting(true)
        const confirmValues = {
            client: selection.client,
            participants: selection.participants,

            // payment gateway could be null for free products
            payment_gateway_id: _get(paymentGateway, "id"),
        }

        if (cartOptions.pickupData) {
            if (!cartOptions.pickupData.ignore) {
                confirmValues.client.pickup = selection.pickup
            } else {
                confirmValues.client.pickup = { ignore_pickup: true }
            }
        }

        onCartConfirm(selection.cart, confirmValues)
            .then((cart) => {
                setSelection((prev) => ({
                    ...prev,
                    cart: cart,
                }))
            })
            .catch((error) => setError(error))
            .finally(() => setWaiting(false))
    }

    // returns the props for the current step
    const getBookingProcessStepProps = (step) => {
        console.log("STEP: ", step)
        switch (step) {
            case STEP_INTERMEDIARY:
                return {
                    step: step,
                    selection: selection,
                    intermediariesFetcher: intermediariesFetcher,
                    featuredIntermediariesFetcher: featuredIntermediariesFetcher,
                    onSelection: onIntermediarySelectionHandler,
                }
            case STEP_PRODUCT:
                return {
                    step: step,
                    selection: selection,
                    categoriesFetcher: categoriesFetcher,
                    productsFetcher: productsFetcher,
                    featuredProductsFetcher: featuredProductsFetcher,
                    onSelection: onProductSelectionHandler,
                    selectorType: intermediariesFetcher !== null ? "simple" : "default",
                }
            case STEP_DATE:
                return {
                    step: step,
                    selection: selection,
                    availabilityFetcher: availabilityFetcher,
                    onSelection: onDateSelectionHandler,
                }
            case STEP_RATES:
                const maxPax = parseInt(_get(selection.product, "qty_restrictions.max", Number.MAX_SAFE_INTEGER))
                return {
                    step: step,
                    selection: selection,
                    ratesFetcher: ratesFetcher,
                    max: Math.min(
                        selection.session.available,
                        maxPax > 0 ? maxPax : Number.MAX_SAFE_INTEGER
                    ),
                    min: parseInt(_get(selection.product, "qty_restrictions.min", 0)),
                    onSelection: onRatesSelectionHandler,
                    onConfirm: onBookingConfirmHandler,
                }
            case STEP_CLIENT:
                return {
                    step: step,
                    selection: selection,
                    pickupData: !_get(cartOptions, "pickupData.ignore", false) ? _get(cartOptions, "pickupData", null) : null,
                    onConfirm: onClientConfirmHandler,
                }
            case STEP_PAYMENT:
                return {
                    step: step,
                    selection: selection,
                    //onPaymentCompleted: onPaymentCompleteHandler,
                }
            default:
                return null
        }
    }

    // returns the component for the current step
    console.log("RENDER")

    if (error) {
        return <Paper elevation={0}><BookingError error={error} onReset={onResetHandler} /></Paper>
    }

    return (
        <Paper elevation={0}>
            <Loading open={waiting} title={t(getStepLoadingMessage(selection.step))} />
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={2}>
                    <ProcessSteps
                        activeStep={selection.step}
                        steps={getSteps(selection.product, t, i18n.language, selection, intermediariesFetcher !== null)}
                        onStepSelected={(step) => updateSelectionForStep(step)}
                    />
                </Grid>
                {selection.step !== STEP_REVIEW && (<Grid item xs={12} md={6}>
                    <BookingProcessStep {...getBookingProcessStepProps(selection.step)} />
                </Grid>)}
                <Grid item xs={12} md={selection.step !== STEP_REVIEW ? 4 : 10}>
                    <BookingWidgetSummary
                        selection={selection}
                        cartOptions={cartOptions}
                        onConfirm={onCartConfirmHandler}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default BookingWidget
