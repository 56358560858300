import { useTranslation } from "react-i18next"
import { FilledInput, FormControl, FormHelperText, Input, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import { formatCurrencyCode } from "../../lib/utils"

const getInputComponent = (variant) => {
    switch (variant) {
        case "outlined":
            return OutlinedInput
        case "filled":
            return FilledInput
        default:
            return Input
    }
}

const processLocale = (locale) => {
    if (locale === "es") {
        return "es-ES"
    }
    return "en-US"
}

const isValidNumber = (value, min, max) => {
    let result = /^-?\d+(\.\d+)?$/.test(value)
    if (result) {
        const number = parseFloat(value)
        result = number >= min && number <= max
    }
    return result
}

const NumericField = (props) => {
    const { i18n } = useTranslation("vbms")
    const fieldProps = { ...props }
    const type = props.type || "numeric"
    const min = props?.min || Number.MIN_SAFE_INTEGER    
    const max = props?.max || Number.MAX_SAFE_INTEGER
    const numberFormatter = new Intl.NumberFormat(processLocale(i18n.language))

    const onChangeHandler = (e) => {
        let value = e.target.value

        value = value.replace(",", ".")

        // remove all dots except the first one
        value = value.replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")

        // Allow only one leading negative sign
        value = value.replace(/(?!^)-/g, "")

        // Remove all non-numeric characters except dot and negative sign
        value = value.replace(/[^0-9.-]/g, "")

        // Ensure the negative sign is at the beginning if it exists
        if (value.includes("-")) {
            value = "-" + value.replace(/-/g, "")
        }

        // Validate the value string to ensure it's a valid number
        const isValid = isValidNumber(value, min, max)

        const parsedValue = isValid ? parseFloat(value) : value

        props.onChange(parsedValue, parsedValue, isValid)
    }

    const InputComponent = getInputComponent(props.variant || "standard")

    let adornment = null
    switch (type) {
        case "currency":
            adornment = <InputAdornment position="end">{formatCurrencyCode(i18n, fieldProps.currency)}</InputAdornment>
            break
        case "percentage":
            adornment = <InputAdornment position="end">%</InputAdornment>
            break
        default:
            break
    }

    return (
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            {props.label && <InputLabel>{props.label}</InputLabel>}
            <InputComponent
                {...fieldProps}
                value={!isValidNumber(props.value, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER) ? props.value : numberFormatter.format(props.value)}
                onChange={onChangeHandler}
                endAdornment={adornment}
                inputProps={{
                    style: { textAlign: 'right' }
                }}
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    )
}

export default NumericField