import { Box, Checkbox, CircularProgress, FormControlLabel, Menu, MenuItem, Stack, TextField } from "@mui/material"
import DataContainer from "../../../components/Display/DataContainer"
import ProductRatesTable from "./ProductRatesTable"
import { useEffect, useState } from "react"
import BaseActionsGroup from "../../../components/action/BaseActionsGroup"
import { filterAvailableActions } from "../../../components/action/utils"
import Title from "../../../components/Display/Title"
import { useTranslation } from "react-i18next"
import { AddOutlined, DeleteOutline, EditOutlined, WarningOutlined } from "@mui/icons-material"
import ProductContainerEditor from "./form/ProductContainerEditor"

const processActions = (actions, refresh) => {
    return (actions || []).map((action) => {
        if (action?.reloadOnSuccess) {
            return {
                ...action,
                onExecute: (data, progressHandler, onSuccess, onError) => {
                    return action.onExecute(data, progressHandler, onSuccess, onError).then(() => refresh())
                }
            }
        }

        return action
    })
}

const initFilter = (filter) => {
    const defaultValues = {
        product: "",
        active: "-",
        visible: "-",
        custom: false,
    }

    if (filter) {
        return {
            ...defaultValues,
            ...filter
        }
    }
}

/**
 * Boolean field with all, true and false options
 * @param {*} param0 
 */
const BooleanField = ({ label, onChange }) => {
    const { t } = useTranslation("vbms")

    const handleChange = (e) => {
        onChange(e.target.value)
    }

    const options = [
        ["-", t("table.all")],
        [true, t("common.yes")],
        [false, t("common.no")]
    ]

    return (
        <TextField
            select
            variant="outlined"
            size="small"
            label={label}
            defaultValue="-"
            onChange={handleChange}
            sx={{ width: 100 }}
        >
            {options.map(([val, label]) => (
                <MenuItem key={val} value={val}>{label}</MenuItem>
            ))}
        </TextField>
    )
}

/**
 * Filter products by name and returns the ids of the products that match the filter
 * 
 * @param {function} onFilter 
 * @returns 
 */
const ProductsFilter = ({ onFilter, hideCustomConditions }) => {
    const { t } = useTranslation("vbms")
    const [filter, setFilter] = useState(initFilter({}))

    useEffect(() => {
        console.log(filter)
        onFilter(filter)
    }, [filter])

    return (
        <Stack direction="row" spacing={2} >
            <TextField
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                label={t("catalog.rates_table.filter.products")}
                defaultValue={filter.product}
                onChange={(e) => {
                    setFilter((prevFilter) => ({
                        ...prevFilter,
                        product: e.target.value
                    }))
                }}
            />

            <BooleanField
                label={t("catalog.rates_table.rates.active")}
                onChange={(value) => {
                    setFilter((prevFilter) => ({
                        ...prevFilter,
                        active: value
                    }))
                }}
            />

            <BooleanField
                label={t("catalog.rates_table.rates.visible")}
                onChange={(value) => {
                    setFilter((prevFilter) => ({
                        ...prevFilter,
                        visible: value
                    }))
                }}
            />

            {!hideCustomConditions && (
                <FormControlLabel
                    control={<Checkbox />}
                    label={t("catalog.rates_table.filter.custom")}
                    onChange={(e) => {
                        setFilter((prevFilter) => ({
                            ...prevFilter,
                            custom: e.target.checked
                        }))
                    }}
                />
            )}
        </Stack>
    )
}

const ProductsTable = ({ products, hideCustomConditions, actions }) => {
    const { i18n } = useTranslation("vbms")
    const [filteredProducts, setFilteredProducts] = useState(products)

    const onFilterHandler = (filter) => {
        if (filter) {
            console.log(filter)
            const productFilter = filter.product && filter.product.length > 0 ? filter.product.toLowerCase() : null
            const filteredProducts = products.filter((product) => {
                let result = true
                if (filter.custom && !product.custom_configuration) {
                    result = false
                }

                if (productFilter) {
                    result = result && (
                        product.name.toLowerCase().includes(productFilter) ||
                        product.experience.name.toLowerCase().includes(productFilter)
                    )
                }

                if (filter.active !== "-") {
                    result = result && product.active === filter.active
                }

                if (filter.visible !== "-") {
                    result = result && product.visible === filter.visible
                }

                return result
            })

            setFilteredProducts(filteredProducts)
        } else {
            setFilteredProducts(products)
        }
    }

    // sort products by name
    const sortedProducts = filteredProducts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), i18n.language, { ignorePunctuation: true }))

    // group products by experience name
    const groupedProducts = sortedProducts.reduce((acc, product) => {
        const experienceName = product.experience.name
        if (!acc[experienceName]) {
            acc[experienceName] = []
        }
        acc[experienceName].push(product)
        return acc
    }, {})

    return (
        <Stack spacing={2} >
            <ProductsFilter onFilter={onFilterHandler} hideCustomConditions={hideCustomConditions} />
            {groupedProducts && Object.keys(groupedProducts).map((experienceName) => (
                <DataContainer key={experienceName} title={experienceName} titleLevel="h4">
                    {groupedProducts[experienceName].map((product) => (
                        <Box
                            key={product.id}
                            display="flex"
                            mb={4}
                        >
                            <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="center"
                                flexDirection="column"
                                flexGrow={1}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <Stack direction="row" justifyContent="space-between" >
                                        <Box
                                            display="flex"
                                            sx={{ "& > div": { fontWeight: 500 } }}
                                        >
                                            {product.custom_configuration && (<WarningOutlined color="warning" sx={{ marginRight: 1 }} />)}
                                            <Title level={"h5"}>{product.name}</Title>
                                        </Box>
                                        <Box mt={-0.5}>
                                            <BaseActionsGroup
                                                selection={product}
                                                variant="outlined"
                                                actions={filterAvailableActions(actions, product)}
                                                actionsNumberDesktop={2}
                                            />
                                        </Box>
                                    </Stack>
                                    <ProductRatesTable product={product} />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </DataContainer>
            ))}
        </Stack>
    )
}

const AsyncProductsTable = ({ fetcher, actions, hideCustomConditions }) => {
    const [products, setProducts] = useState([])
    const [ready, setReady] = useState(false)

    const refresh = () => {
        setReady(false)
        fetcher().then((products) => {
            setProducts(products)
            setReady(true)
        })
    }

    useEffect(() => {
        refresh()
    }, [])

    return (
        <Box>
            {!ready && <Box display="inline-flex" alignItems="center"><CircularProgress size={20} /></Box>}
            {ready && <ProductsTable products={products} actions={processActions(actions, refresh)} hideCustomConditions={hideCustomConditions} />}
        </Box>
    )
}

const AsyncEntityProductsTable = ({ entity, fetcher, actionHandlers, hideCustomConditions }) => {
    const { t } = useTranslation("vbms")

    const actions = [
        {
            id: "add",
            title: t("common.add"),
            scope: "row",
            icon: AddOutlined,
            confirmDialog: {
                title: t("catalog.product_containers.actions.product_add.modal_title"),
                content: (selection) =>
                    t("catalog.product_containers.actions.product_add.modal_content", {
                        name: selection.name,
                        container_name: entity.name,
                    }),
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (product, progressHandler, onSuccess, onError) => {
                const data = {
                    add: [{
                        id: product.id,
                        experience_id: product.experience.id,
                        commission_percentage: 0,
                        active: true,
                        visible: false,
                    }]
                }

                return actionHandlers.add(entity.id, data)
                    .then((result) => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_add.confirm")
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_add.error")
                        )
                    })
            },
            condition: (entity) => actionHandlers?.add && entity.hasAction("add"),
        },
        {
            id: "edit",
            title: t("common.edit"),
            scope: "row",
            icon: EditOutlined,
            withDialog: {
                title: t("catalog.product_containers.actions.product_edit.modal_title"),
                content: ProductContainerEditor,
                maxWidth: "md",
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (result, progressHandler, onSuccess, onError) => {
                return actionHandlers.edit(entity.id, result.id, result.data)
                    .then((product) => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_edit.confirm", {
                                name: result.name,
                            })
                        )

                        return product
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_edit.error", {
                                name: result.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (entity) => actionHandlers?.edit && entity.hasAction("edit"),
        },
        {
            id: "delete",
            title: t("common.delete"),
            scope: "row",
            icon: DeleteOutline,
            confirmDialog: {
                title: t("catalog.product_containers.actions.product_delete.modal_title"),
                content: (selection) =>
                    t("catalog.product_containers.actions.product_delete.modal_content", {
                        name: selection.name,
                    }),
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (product, progressHandler, onSuccess, onError) => {
                return actionHandlers.delete(entity.id, product.id)
                    .then(() => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_delete.confirm", {
                                name: product.name,
                                container_name: entity.name,
                            })
                        )

                        return entity
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_delete.error", {
                                name: entity.name,
                                container_name: entity.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (entity) => actionHandlers?.delete && entity.hasAction("delete"),
        }
    ]

    return <AsyncProductsTable fetcher={fetcher} actions={actions} hideCustomConditions={hideCustomConditions} />
}


export default AsyncEntityProductsTable