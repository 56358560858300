import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import AsyncEntityProductsTable from "../../../../components/ProductsTable"

const RateGroupProducts = ({ entity }) => {
    const { apiClient } = useVolcanoApiClient()

    if (!entity) {
        return null;
    }

    const fetcher = () => 
        apiClient.catalog.rateGroup.getRateGroupProducts(entity.id, {limit: 1000})
        .then((collection) => {
            return collection.getItems().map((product) => {
                product.container = {
                    id: entity.id,
                    name: entity.name,
                }

                return product
            })
    }) 

    const actionHandlers = {
        edit: (containerId, productId, data) => apiClient.catalog.rateGroup.editRateGroupProduct(containerId, productId, data),
        delete: (containerId, productId) => apiClient.catalog.rateGroup.deleteRateGroupProduct(containerId, productId),
    }

    return <AsyncEntityProductsTable entity={entity} fetcher={fetcher} actionHandlers={actionHandlers} hideCustomConditions={true} />
}

export default RateGroupProducts